import isObject from 'lodash/isObject'
import { getQueryString, stringifyQueryString } from '@shein/common-function'
const { langPath } = gbCommonInfo
import analysis from '../analysis'
import { getSearchSourceBySearchType } from './searchConst'
import { spaJump } from 'public/src/pages/common/utils/index.js'
import { getCurrentInstance } from 'vue'
import { handlePicTopNavQueryChain } from 'public/src/pages/components/FilterBar/PicTopNav/utils/index.js'

const useStore = () => {
  const vm = getCurrentInstance()
  if (!vm) return console.warn('useStore must be used after setup')
  return vm?.proxy?.$store
}

const useRouter = () => {
  const vm = getCurrentInstance()
  if (!vm) throw new Error('must be called in setup')
  return vm.proxy.$router
}

export function useGoSearch(store = useStore(), router = useRouter()) {
  // 进入预搜页的src_identifier额外处理
  const handleSrcIdentifier = srcIdentifier => {
    const keyArr = ['on', 'cn', 'hz', 'ps', 'jc']
    let tempSrcIdentifierObj = {}
    keyArr.forEach(keyVal => {
      tempSrcIdentifierObj[`src_identifier_${keyVal}`] = ''
    })

    if (srcIdentifier) {
      let idx = 0
      srcIdentifier.split('`').forEach(item => {
        let key = item.split('=')[0]
        if (keyArr.includes(key)) {
          idx++
          tempSrcIdentifierObj[`src_identifier_${key}`] = item.split('=')[1]
        }
      })
      if (idx > 1) {
        gbCommonInfo.src_identifier_pre_search = tempSrcIdentifierObj  // 全局记录一个src_identifier_pre_search，下游服务需要，因为后面预搜页去搜索页src_identifier这个字段会被修改
      }
    } else {
      // url上没有src_identifier的时候，src_identifier_pre_search里面对应的字段传空
      gbCommonInfo.src_identifier_pre_search = tempSrcIdentifierObj
    }
  }

  const goSearchPage = ({
    keyword,
    result_type = 2,
    position = 0,
    guideObj: { search_id, search_cat, full_name, trend_list_content = '' } = {},
    backToPreSearch = false,
    originWord,
    pageType,
    channelId,
    isClickTopTrendSearchButton,
    from_list_cate_id = '',
    analysis = true,
    extraQuery = {},
  } = {}) => {
    let url, params = {}
    // let fromSearch = this.$route.query.fromSearch
    let fromSearch = router.query?.fromSearch
    const ana_keyword = isObject(keyword) ? keyword?.word : keyword
    const fixedKeyword = ana_keyword?.replace?.(/%/g, '%25') || '' // VueRouter重decode兼容
    // 防止空搜索词跳转
    if(fixedKeyword === '') return
    url = `/pdsearch/${encodeURIComponent(fixedKeyword)}/`
    if (search_id) {
      params.search_id = search_id
      params.search_cat = search_cat
    }
    Object.assign(params, handlePicTopNavQueryChain())

    // const originWord = result_type == 8 ? this.$refs.searchInput.curKeywordCopy : ana_keyword
    if (result_type !== 8) {
      originWord = ana_keyword
    }
    gbCommonInfo.keywords = ana_keyword
    store.commit('assignRootState', { mixedKeyword: ana_keyword }) // 保存搜索词
    // this.assignRootState({ mixedKeyword: ana_keyword }) // 保存搜索词
    // S-analysis
    let urlTrackData = {}
    if (analysis) {
      urlTrackData = reportedPoints({
        keyword,
        full_name,
        result_type,
        search_redir: params.search_redir,
        position,
        originWord,
        trend_list_content,
        pageType,
        isClickTopTrendSearchButton
      })
    }
    // E
    // if (this.channelId) {
    //   params.channel_id = this.channelId // 频道ID
    // }
    if (channelId) {
      params.channel_id = channelId // 频道ID
    }
    const srcModuleMap = {
      all: 'search',
      store: 'store_search',
      brand: 'chanel_search',
      pageTrend: 'trend_search',
    }

    const srcIdentifier = getQueryString({ key: 'src_identifier' }) || ''
    handleSrcIdentifier(srcIdentifier)
    
    const optionParams = {
      // src_module: srcModuleMap[this.pageType] ?? 'search', 
      src_module: srcModuleMap[pageType] ?? 'search',
      src_identifier: `st=${result_type}\`sc=${originWord}\`sr=${
        result_type == 8 ? ana_keyword : 0
      }\`ps=${position}`,
      src_tab_page_id: getSaPageInfo?.tab_page_id || '', // 来源页面
    }
    if (from_list_cate_id) {
      optionParams.from_list_cate_id = from_list_cate_id
    }
    if (pageType === 'store') {
      Object.assign(optionParams, {
        search_type: 'store',
        store_code: getQueryString({ key: 'store_code' }),
        brand_id: getQueryString({ key: 'brand_id' }),
        dont_use_suggest_word: 1 // 固定词
      })
    }
    // 频道页
    if (pageType === 'pageTrend') {
      optionParams.search_type = pageType
      optionParams.entry_from = 'page_to_trend`-`-`top_site_search'
    }
    // 新增 search_source
    // if (this.pageType === 'all') {
    //   optionParams.search_source = getSearchSourceBySearchType(result_type)
    // }
    // 新增 search_source
    if (pageType === 'all') {
      optionParams.search_source = getSearchSourceBySearchType(result_type)
    }
    url = getSkipUrl(url, params, urlTrackData, Object.assign({}, extraQuery, optionParams))
    if (backToPreSearch && !fromSearch) {
      // 从首页进来且下次回退需要回到联想页
      url += '&backto=preSearch&show_association=1'
    }

    fromSearch == 'in' && (fromSearch = 'jump')
    spaJump({
      // routerVm: this.$router,
      routerVm: router,
      type: fromSearch ? 'replace' : 'push',
      url,
    })
  }

  const getSkipUrl = (url, params, urlTrackData, options = {}) => {
    let resultUrl = `${url}${
      url.includes('?') ? '&' : '?'
    }${stringifyQueryString({
      queryObj: { ...params, ...urlTrackData, ...options },
    })}`
    if (!/^https?:\/\//.test(resultUrl)) {
      // 非完整链接需要处理一下
      resultUrl = langPath + resultUrl.trim().replace('@', '-')
    }
    return resultUrl
  }
  const reportedPoints = ({
    keyword,
    full_name,
    result_type,
    search_redir,
    position,
    originWord,
    trend_list_content,
    pageType,
    isClickTopTrendSearchButton // 是否是点击趋势频道搜索框右侧button按钮
  }) => {
    const ana_keyword = keyword?.word || keyword
    const ana_crowdId = keyword?.crowdId || 0
    if (isClickTopTrendSearchButton) return // 点击趋势频道搜索框右侧button按钮不上报 因为它们自己上报了
    const result = analysis.search({
      keyword: ana_keyword,
      full_name: full_name || ana_keyword,
      result_type,
      search_redir,
      data: {
        position,
        type: keyword?.type ?? '',
      },
      crowdId: ana_crowdId,
      originWord,
      // pageType: this.pageType,
      pageType,
      trend_list_content
    })
    return result
  }
  return { goSearchPage }
}
