import { getQueryString } from '@shein/common-function'

// 判断是否全部是品类图文
export function getIsAllCategoryType ({ picTopNav }) {
  if (!picTopNav.length) return false

  return picTopNav
    .reduce((arr, item) => {
      arr.push(...item.navs)
      return arr
    }, [])
    .every(_ => _.nav_type == '1')
}

export function handlePicTopNavParams ({ list, tabIndex = 0, navIndex, extraParams = {}, isSelect, noClearParams, businessReport }) {
  const tabItem = list[tabIndex]
  const navItem = list[tabIndex].navs[navIndex]
  const pic_tab_pos = tabIndex + 1
  const { nav_id, nav_type, nav_name, goodsId = '', rec_type } = navItem
  navItem.index = navIndex

  const params = {
    pic_nav_adp: goodsId,
    pic_nav_id: nav_id,
    pic_nav_type: nav_type,
    pic_nav_name: nav_name,
    pic_nav_pos: navIndex + 1,
    pic_rec_type: rec_type,
    pic_tab_id: tabItem.tabId,
    pic_tab_type: tabItem.tabType,
    pic_tab_pos: isNaN(pic_tab_pos) ? '' : pic_tab_pos,
    ...extraParams
  }
  
  // 非取值埋点参数时，需要走取消选中清空参数的逻辑
  if (!noClearParams) !isSelect && Object.keys(params).forEach(key => params[key] = '')

  // 业务指标上报
  if (businessReport) Object.assign(params, { source: 'imgNav', source_status: isSelect ? 1 : 0 })

  return params
}

/**
 * 处理点击图文跳转后续页面必带的参数
 */
export function handlePicTopNavQueryChain() {
  const queryChain = {}
  const entranceType = getQueryString({ key: 'entranceType' }) || ''
  const fromPageType = getQueryString({ key: 'fromPageType' }) || ''

  if (entranceType) {
    queryChain.entranceType = entranceType
  }

  if (fromPageType) {
    queryChain.fromPageType = fromPageType
  }

  return queryChain
}